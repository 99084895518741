<script setup>
import { mapErrors } from '@/utilities';
import { useForm } from '@inertiajs/vue3';
import { inject } from 'vue';

const dayjs = inject('dayjs');
const route = inject('route');

const props = defineProps({
    flexer: Object,
});

const form = useForm({
    user_id: props.flexer.id,
});

const submitForm = () => {
    form.post(route('staff.password-reset-link.store'), {
        preserveState: true,
        preserveScroll: true,
        onError: (errors) => (form.errors = mapErrors(errors)),
    });
};

const submitTwoFactorForm = () => {
    form.post(route('staff.two-factor.destroy'), {
        preserveState: true,
        preserveScroll: true,
        onError: (errors) => (form.errors = mapErrors(errors)),
    });
};
</script>
<template>
    <div>
        <div class="mb-2 text-2xl font-bold text-blue">{{ $t('Security') }}</div>
        <div class="text-base border-t border-b divide-y divide-blue/30 spacing-2 border-blue/30">
            <div class="grid grid-cols-3 py-4">
                <span>{{ $t('Password') }}</span>
                <span></span>
                <button
                    type="button"
                    class="font-bold text-right transition-colors duration-300 text-blue-light hover:text-blue justify-self-end"
                    v-html="$t('Reset')"
                    @click="submitForm"
                ></button>
            </div>
            <div class="grid grid-cols-3 py-4">
                <span>{{ $t('2-factor authentication') }}</span>
                <span></span>
                <button
                    type="button"
                    class="font-bold text-right transition-colors duration-300 text-blue-light hover:text-blue justify-self-end"
                    v-html="$t('Reset')"
                    @click="submitTwoFactorForm"
                ></button>
            </div>
        </div>
    </div>
</template>
